html {
	scroll-snap-type: y mandatory;
}

.app {
	height: 100vh;
	background-color: black;
	display: grid;
	place-items: center;
}

.app__videos {
	position: relative;
	height: 800px;
	border-radius: 20px;
	overflow: scroll;
	width: 80%;
	max-width: 500px;
	scroll-snap-type: y mandatory;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app__videos::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app__videos {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
