.video {
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	scroll-snap-align: start;
}

.video__player {
	object-fit: fill;
	width: 100%;
	height: 100%;
}
