.videoFooter {
	position: relative;
	color: white;
	bottom: 150px;
	margin-left: 40px;
	display: flex;
}

.videoFooter__text {
	flex: 1;
}

.videoFooter__text > p {
	padding-bottom: 20px;
}

.videoFooter__record {
	animation: spinTheRecord infinite 5s linear;
	height: 50px;
	filter: invert(1);
	position: absolute;
	bottom: 0;
	right: 20px;
}

.videoFooter__icon {
	position: absolute;
}

.videoFooter__ticker > .ticker {
	height: fit-content;
	margin-left: 30px;
	width: 60%;
}

.videoFooter__text > h3 {
	padding-bottom: 20px;
}

.videoFooter__ticker h1 {
	padding-top: 7px;
}

@keyframes spinTheRecord {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
